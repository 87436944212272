import styled from '@emotion/styled';
import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import NextLink from 'next/link';
import * as assetsConfig from '../../config/assets';
import { contentfulAssetURL } from '../../config/assets';

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      src={`${assetsConfig.prefix + 'top/'}carousel_next.svg`}
      className={className}
      onClick={onClick}
      alt="next"
      style={{ ...style, width: '48px', height: '48px', zIndex: 2, top: '50%' }}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      src={`${assetsConfig.prefix + 'top/'}carousel_prev.svg`}
      className={className}
      onClick={onClick}
      alt="prev"
      style={{ ...style, width: '48px', height: '48px', zIndex: 2, top: '50%' }}
    />
  );
}

const WhitepapersCarousel = ({ whitepapers }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    afterChange: (current) => setCurrentSlide(current),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: true,
          variableWidth: true,
          dots: false,
        },
      },
    ],
  };

  return (
    <Box px={{ xs: 0, sm: 2, md: 17 }} pb={{ xs: 0, sm: 4, md: 6 }}>
      <StyleSlider {...settings}>
        {whitepapers.map((whitepaper, index) => (
          <NextLink key={index} href={`/whitepapers/${whitepaper.slug}/`} passHref>
            <WhitepaperSection>
              <ImageContainer>
                <img src={contentfulAssetURL(whitepaper.thumbnailURL)} alt={whitepaper.title} />{' '}
                {/* next/image を使用するように変更する必要があります */}
              </ImageContainer>
              <Box px={1}>
                <InfoBox>
                  <span>{whitepaper.title}</span>
                </InfoBox>
              </Box>
            </WhitepaperSection>
          </NextLink>
        ))}
      </StyleSlider>
      <Box
        sx={{ display: { xs: 'flex', sm: 'none' }, justifyContent: 'center', alignItems: 'center', marginTop: '8px' }}
      >
        <Typography variant="body2" sx={{ fontFamily: 'Inter' }}>
          {currentSlide + 1}/{whitepapers.length}
        </Typography>
      </Box>
    </Box>
  );
};

export default WhitepapersCarousel;

const StyleSlider = styled(Slider)`
  .slick-slide {
    @media (max-width: 600px) {
      width: 330px !important;
    }
  }
  .slick-track {
    @media (max-width: 600px) {
      display: flex !important;
      width: fit-content !important;
    }
  }
  .slick-dots li.slick-active button:before {
    color: #ffd900;
  }
  .slick-dots {
    position: relative;
    top: 10px;
  }

  .slick-next,
  .slick-prev {
    transition: all 0.2s ease-in-out;
    &:hover {
      opacity: 0.8;
    }
  }
  .slick-next {
    right: 0px !important;
    @media (min-width: 1024px) {
      right: -8px !important;
    }
  }
  .slick-prev {
    left: 0px !important;
    @media (min-width: 1024px) {
      left: -8px !important;
    }
  }
`;

const WhitepaperSection = styled(Box)`
  position: relative;
  height: fit-content;
  margin: 0 16px;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
  &:hover {
    opacity: 0.8;
  }
  @media (max-width: 600px) {
    margin: 0 8px;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  object-fit: fill;
  border-radius: 8px;

  img {
    height: 100%;
    width: 100%;
    object-fit: fill;
  }
`;

const InfoBox = styled(Box)`
  position: relative;
  width: 100%;
  bottom: 8px;
  @media (max-width: 600px) {
    bottom: 16px;
  }
  span {
    font-size: 16px;
    font-weight: 700;
    padding: 16px;
    @media (max-width: 600px) {
      font-size: 18px;
      padding: 24px;
    }
  }
  height: fit-content;
  border-radius: 8px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
`;
